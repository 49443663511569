body {
  margin: 0;
  font-family: monospace, Consolas, 'Courier New';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  height: 100%;
  overflow: auto;
  font-size: 18px;
}

select {
  font-family: Menlo, Monaco, Consolas, 'Courier New';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow: hidden;
  height: 100%;
}

* {
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

p {
  margin: 0;
}

h1 { font-size: 3.5em; }
h3 { font-size: 1.6em; }

@media only screen and (max-width: 420px) {
  h1 { font-size: 2.5em; }
  h3 { font-size: 1.2em; }
}

a {
  color: black;
}

a:hover, .link:hover {
  cursor: pointer;
  font-weight: bold;
}

.link {
  text-decoration: underline;
}

input, button, textarea {
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none !important;
  border-radius: 6px;
  border-style: solid;
  border-width: 1.5px;
  padding: 10px;
  font-size: large;
  margin: 0;
}

input[type='password'] {
  letter-spacing: 2px;
}

input[type='password']::placeholder {
  letter-spacing: normal;
}

.pr-0 { padding-right: 0 }
.pt-0 { padding-top: 0 }
.pb-0 { padding-bottom: 0 }

.input-no-style {
  all: unset;
}

.validity:invalid, .invalid {
  border-color: red !important;
}

.container {
  background: white;
  border: 1px solid;  
  box-shadow: 3px 3px 6px #00000040;
  text-align: center;
  padding: 2em;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 1.5em;
  }
}

@media only screen and (max-width: 350px) {
  .container {
    padding: 1em;
    border-width: 2px;
  }
}

@media only screen and (max-width: 300px) {
  .container {
    padding: 0;
    border: 0;
    background: none;
    box-shadow: unset;
  }
}

.error { color: red; }
.dangerous-hover:hover { color: red }

.loading, .disabled {
  color: grey;
  cursor: default;
}

.hover-bold:hover {
  cursor: pointer;
  font-weight: bold;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.fixed {
  /* position: sticky; not playing nicely with child dropdown with position absolute */
  position: fixed;
  z-index: 100;
}

.max-screen-width {
  max-width: 50em;
}

.bold {
  font-weight: bold;
}

.grey {
  color: grey;
}

/* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* https://stackoverflow.com/questions/37926864/hide-text-but-have-it-show-up-if-copied-and-pasted-without-javascript */
.hidden-but-copy-pastable-text {
  color: transparent;
  font-size: 0;
}

.dotted-border-bottom {
  border-bottom: 1px dotted;
}

.dashboard-with-table {
  padding-top: 80px;
  margin: auto;
}

.dashboard-with-table>div {
  width: 75%;
  margin: auto;
}

.dashboard-with-table-total {
  font-size: large;
}

.dashboard-with-table-outer-container {
  padding-top: 25px;
}

.dashboard-with-table .container {
  min-height: 60vh;
  padding: 1em;
  margin-bottom: 4em;
}

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-table th {
  text-align: left;
  padding-bottom: 1em;
}

.dashboard-table td {
  padding-bottom: .8em;
  padding-top: .8em;
  text-align: left;
}

.dashboard-table tr {
  border-bottom: 1px solid lightgrey;
}

.dashboard-table tr:first-child {
  border-top: 1px solid lightgrey;
}

.dashboard-table tbody tr:hover {
  background-color: #eeeeee;
}

.dashboard-table th:first-child,
.dashboard-table td:first-child {
  padding-left: 1em;
}

.dashboard-table th:last-child,
.dashboard-table td:last-child {
  padding-right: 1em;
}

.dashboard-table thead tr {
  border-bottom: 1px solid black;
}

.create-doc-to-get-started {
  margin-top: 22%;
  padding-bottom: 22%;
}

.dangerous {
  color: red;
}

.float-right { float: right; }
.float-left { float: left; }